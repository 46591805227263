import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"

import { Section, Container } from "../global"
import Img from "gatsby-image"

const Walkthrough = () => {
  const data = useStaticQuery(graphql`
    query {
      connect: file(sourceInstanceName: { eq: "product" }, name: { eq: "connect" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      calendar: file(sourceInstanceName: { eq: "product" }, name: { eq: "calendar" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      relax: file(sourceInstanceName: { eq: "product" }, name: { eq: "relax" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
  <Section id="how it works">
    <StyledContainer>
      <Subtitle>How it works</Subtitle>
      <SectionTitle>Break free from lump sums</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <StyledImage fluid={data.connect.childImageSharp.fluid} />
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Connect your account</FeatureTitle>
          <FeatureText>
            Securely link your account using Plaid, a trusted provider across
            the financial industry.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <StyledImage fluid={data.calendar.childImageSharp.fluid} />
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Pick your days</FeatureTitle>
          <FeatureText>
            Select the days and amounts that work best for you. 
            Plan around your personal financial situation.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <StyledImage fluid={data.relax.childImageSharp.fluid} />
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Sit back and relax</FeatureTitle>
          <FeatureText>
            Your payments will happen automatically on the days you selected. 
            We'll handle the original payment every month.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
  )
}

export default Walkthrough

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`

const StyledImage = styled(Img)`
  width: 100%;
`
